import React from 'react'
import { Container } from "react-bootstrap"
import "./WebinarButton.scss"
const {
    PreviewCTALink,
  } = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const WebinarButton = ({data}) => {
  return (
    <div className="webinar-button-wrapper">
        <Container>
            <PreviewCTALink
            class="button button-filled-green"
            link={data?.link ? data?.link:{external_link: data?.custom_link}}
            title={data.title}
            target_window={data?.custom_link?.startsWith("https") ? "":""}
            />
        </Container>
    </div>
  )
}

export default WebinarButton